import {Clear, Edit} from "@mui/icons-material";
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../actions";
import BaseLoader from "./utils/BaseLoader";
import {CreateTagDialog} from "./utils/ChipsInputCreatable";
import TagChip from "./utils/TagChip";

const mapStateToProps = state => ({tags: state.getTags});

const ManageOrdersTags = ({tags, actions}) => {
	const [editedTag, setEditedTag] = useState(null);
	const [deletedTag, setDeletedTag] = useState(null);
	const handleCloseEditDialog = () => setEditedTag(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		actions.getAllTags();
	}, []);

	useEffect(() => {
		if (tags) {
			setLoading(false);
		}
	}, [tags]);

	const handleDialogSubmit = (e) => {
		e.preventDefault();
		const {name, color, original} = editedTag;
		if (!original) {
			actions.createTag({name, color});
		} else if (name !== original.name || color !== original.color) {
			actions.editTag(original.name, {name, color});
		}
		handleCloseEditDialog();
	}

	const handleDeleteTag = () => {
		actions.deleteTag(deletedTag.name);
		setDeletedTag(null);
	}

	if (loading) return <BaseLoader loading={true}/>
	return (
		<>
			<Dialog open={!!deletedTag} onClose={() => setDeletedTag(null)}>
				<DialogTitle>Usuń etykietę</DialogTitle>
				<DialogContent>
					<DialogContentText>Czy jesteś pewien, że chcesz usunąć etykietę "{deletedTag?.name}"?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						onClick={() => setDeletedTag(null)}
					>
						Anuluj
					</Button>
					<Button
						disableElevation
						variant="contained"
						onClick={handleDeleteTag}
					>
						Usuń
					</Button>
				</DialogActions>
			</Dialog>
			<CreateTagDialog
				open={!!editedTag}
				dialogValue={editedTag}
				setDialogValue={setEditedTag}
				handleClose={() => setEditedTag(null)}
				handleSubmit={handleDialogSubmit}
				availableTagsNames={tags.map(({name}) => name)}
				dialogTitle={editedTag?.original ? 'Edytuj etykietę' : 'Nowa etykieta'}
				dialogContentText={editedTag?.original ? 'Czy chesz edytować etykietę' : 'Czy chcesz dodać nową etykietę?'}
				submitButtonText={editedTag?.original ? 'Dalej' : 'Utwórz'}
			/>
			<Button
				variant="contained"
				disableElevation
				onClick={() => setEditedTag({name: '', color: 'grey'})}
				sx={{mb: 4}}
			>
				Utwórz etykietę
			</Button>
			<Table>
				<TableBody>
					{tags.length ? tags.map((tag) => <TableRow hover key={tag.name}>
							<TableCell>
								<TagChip
									key={tag.name}
									label={tag.name}
									bgCol={tag.color}
								/>
							</TableCell>
							<TableCell width={160}>
								<Button
									variant="outlined"
									onClick={() => setEditedTag({...tag, original: {...tag}})}
									endIcon={<Edit/>}
								>
									Edytuj
								</Button>
							</TableCell>
							<TableCell width={160}>
								<Button variant={'outlined'} onClick={() => setDeletedTag(tag)}
								        endIcon={<Clear/>}
								>
									Usuń
								</Button>
							</TableCell>
						</TableRow>,
					) : (
						<Grid item xs={12}>
							<Alert severity="info">
								Brak etykiet
							</Alert>
						</Grid>
					)}
				</TableBody>
			</Table>
		</>
	)
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});
export default connect(mapStateToProps, mapDispatchToProps)(ManageOrdersTags);
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	Button,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions';
import BaseLoader from "../utils/BaseLoader";
import StyledTableCell from '../utils/StyledTableCell';
import CreateDeviceModal from './CreateDeviceModal';

const providerName = {
	fiber_auth: 'FiberToken',
	google_auth: 'Google Authenticator',
};

function mapStateToProps(state) {
  return {
    devices: state.get2FADevices,
  };
}

class TwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      devices: [],
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.actions.get2FADevices();
  }

  componentDidUpdate(prevProps) {
    const { devices } = this.props;
    if (prevProps.devices !== devices) {
      this.setState({
        loading: false,
        dialogOpen: false,
        devices,
      });
    }
  }

  handleSetDefault = deviceCode => {
    this.props.actions.setDefault2FADevice(deviceCode);
  };

  handleRemove = deviceCode => {
    this.props.actions.remove2FADevice(deviceCode);
  };

  toggleDialog = () => this.setState({ dialogOpen: !this.state.dialogOpen });

  deviceRow = device => {
    const { provider, code, name, isDefault } = device;

    return (
      <TableRow key={code}>
        <TableCell>{name}</TableCell>
        <TableCell>{providerName[provider] || provider}</TableCell>
        <TableCell padding='none'>
          <Switch
            color='primary'
            checked={isDefault}
            onChange={() => {
              if (isDefault) return;
              this.handleSetDefault(code);
            }}
          />
        </TableCell>
        <TableCell padding='none'>
          {provider !== 'email' && (
            <IconButton
              aria-label='delete'
              onClick={() => this.handleRemove(code)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  render() {
	  const {loading, devices, dialogOpen} = this.state;

	  if (loading) return <BaseLoader loading/>

	  return (
		  <>
			  <Button variant="outlined" color="primary" onClick={this.toggleDialog}>
				  Dodaj nowe urządzenie
			  </Button>
			  <Box my={1}>
				  <TableContainer>
					  <Table>
						  <TableHead>
                <TableRow>
                  <StyledTableCell>Nazwa</StyledTableCell>
                  <StyledTableCell>Typ</StyledTableCell>
                  <StyledTableCell>Domyślne</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map(device => this.deviceRow(device))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <CreateDeviceModal open={dialogOpen} handleClose={this.toggleDialog} />
		  </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuth);

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button,
  Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const providerOptions = [
  { key: 'fa', value: 'fiber_auth', text: 'FiberToken' },
  { key: 'ga', value: 'google_auth', text: 'Google Authenticator' },
];

const initialState = {
  isOpen: false,
  name: '',
  provider: '',
  default: true,
};

const PREFIX = "CreateDeviceModal";
const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  formControl: `${PREFIX}-formControl`,
  dialogActions: `${PREFIX}-dialogActions`,
}
const Root = styled(Dialog)(({theme}) => ({
  [`& .${classes.dialogContent}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.formControl}`]: {
    marginTop: theme.spacing(2),
    minWidth: 320,
  },
  [`& .${classes.dialogActions}`]: {
    margin: theme.spacing(1),
  },
}));

class CreateDeviceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleChange = ({ target }) => {
    let { name, value, type, checked } = target;
    if (type === 'checkbox') {
      value = checked;
    }

    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.canBeSubmitted()) return;
    this.props.actions.create2FADevice({
      deviceName: this.state.name,
      provider: this.state.provider,
      default: this.state.default,
    });

    this.handleClose();
  };

  handleClose = () => {
    this.setState(initialState);
    this.props.handleClose();
  };

  canBeSubmitted = () => {
    const { name, provider } = this.state;

    return !!(provider && name.length && name.length <= 20);
  };

  render() {
    const { open } = this.props;
    const { name, provider } = this.state;
    return (
      <Root open={open}>
        <form onSubmit={this.handleSubmit}>
          <DialogTitle>Utwórz nowe urządzenie</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextField
              id='name'
              name='name'
              label='Nazwa'
              variant='outlined'
              value={name}
              onChange={this.handleChange}
              className={classes.formControl}
            />
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='provider'>Rodzaj</InputLabel>
              <Select
                labelId='provider'
                value={provider}
                label='Rodzaj'
                name='provider'
                onChange={this.handleChange}
              >
                {providerOptions.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.default}
                  name='default'
                  color='primary'
                  onChange={this.handleChange}
                />
              }
              label='Ustaw jako domyślne'
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={this.handleClose} color='primary'>
              Anuluj
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disableElevation
              disabled={!this.canBeSubmitted()}
            >
              Utwórz
            </Button>
          </DialogActions>
        </form>
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(null, mapDispatchToProps)(CreateDeviceModal);

import {Box, Tab, Tabs} from "@mui/material";
import React, {useState} from 'react';
import TwoFactorAuthPanel from '../../components/2fa/TwoFactorAuthPanel';
import ApiKeys from '../../components/ApiKeys';
import ManageOrdersTags from "../../components/ManageOrdersTags";
import BasePageContent from "../../components/utils/BasePageContent";
import Layout from '../../templates/layout';

const TabPanel = ({children, tabIdx, index, ...other}) => (
	<Box
		role="tabpanel"
		hidden={tabIdx !== index}
		id={`tabpanel-${index}`}
		aria-labelledby={`tab-${index}`}
		{...other}
	>
		{tabIdx === index && <Box sx={{py: 4}}>{children}</Box>}
	</Box>
);

const TwoFactorAuth = () => {
	const [tabIdx, setTabIdx] = useState(0);
	const handleChange = (_, tabIdx) => setTabIdx(tabIdx);

	return (
		<Layout >
			<BasePageContent title="Ustawienia">
				<Box
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
					}}
				>
					<Tabs
						value={tabIdx}
						onChange={handleChange}
						aria-label="Zakładki płatności"
					>
						<Tab label="Etykiety płatności" id="tab-0" aria-controls="tabpanel-0"/>
						<Tab label="Silne uwierzytelnianie" id="tab-1" aria-controls="tabpanel-1"/>
						{/* <Tab label="Klucze API" id="tab-2" aria-controls="tabpanel-2"/> */}
					</Tabs>
				</Box>
				<TabPanel tabIdx={tabIdx} index={0}>
					<ManageOrdersTags/>
				</TabPanel>
				<TabPanel tabIdx={tabIdx} index={1}>
					<TwoFactorAuthPanel/>
				</TabPanel>
				<TabPanel tabIdx={tabIdx} index={2}>
					<ApiKeys />
				</TabPanel>
			</BasePageContent>
		</Layout>
	);
}

export const Head = () => <title>Ustawienia | Zapłatomat</title>;

export default TwoFactorAuth;
